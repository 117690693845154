import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../utils/htmlSerializer"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Button from "../components/shared/button"
import Banner from "../components/widgets/banner"
import PageLink from "../components/shared/pageLink"
import Image from "../components/shared/image"
import Reveal from "react-reveal/Reveal"

const CaseStudy = ({ item }) => (
  <li
    className={`c-staggered-list__item`}
    style={{
      "--theme-color": item.theme_color1,
      "--a11y-theme-color": item.a11y_theme_color,
    }}
    ie-style={`--theme-color: ${item.theme_color1}; --a11y-theme-color: ${item.a11y_theme_color}`}
  >
    <PageLink item={item} className={`c-case-study-link`}>
      <div className={`c-case-study-link__image`}>
        <span className={`c-case-study-link__image__container`}>
          <Image
            sharpType="fluid"
            sharpImage={item.feature_imageSharp.childImageSharp.fluid}
            alt={item.feature_image.alt}
            defaultImage={item.feature_image.url}
          />
        </span>
      </div>
      <div className={`c-staggered-list__content`}>
        <p>{item.key_question}</p>
        <div className={`c-staggered-list__content__title u-color-theme`}>
          <strong>{item.title[0].text}</strong>
        </div>
      </div>
    </PageLink>
  </li>
)

const WorkPage = ({ data }) => {
  const doc = data.prismic.allOur_works.edges.slice(0, 1).pop()
  if (!doc) return null

  const completedProjects = data.prismic.allCase_studys.edges.filter(
    project =>
      project.node.project_status === "Online" ||
      project.node.project_status === "Archived"
  )
  const ongoingProjects = data.prismic.allCase_studys.edges.filter(
    project => project.node.project_status === "In-Progress"
  )

  return (
    <Layout pageTitle={doc.node.page_heading[0].text}>
      <SEO
        title={
          doc.node.meta_title
            ? doc.node.meta_title
            : doc.node.page_heading[0].text
        }
        image={doc.node.meta_image}
        description={doc.node.meta_description}
      />

      <Banner content={doc.node.banner_title} color="red" />

      <section className={`grid-container`}>
        <div className={`grid-x`}>
          <div className={`cell`}>
            <Reveal effect="fadeInUp" fraction={0.05}>
              <ul className={`c-staggered-list`}>
                {completedProjects.map((project, index) => (
                  <CaseStudy item={project.node} key={index} />
                ))}
              </ul>
            </Reveal>
          </div>
        </div>
      </section>

      <Banner content={doc.node.secondary_title} color="red" />

      <section className={`grid-container`}>
        <div className={`grid-x`}>
          <div className={`cell`}>
            <Reveal effect="fadeInUp" fraction={0.05}>
              <ul className={`c-staggered-list`}>
                {ongoingProjects.map((project, index) => (
                  <CaseStudy item={project.node} key={index} />
                ))}
              </ul>
            </Reveal>
          </div>
        </div>
      </section>

      <aside className={`c-aside grid-container`}>
        <div className={`c-aside__content grid-x`}>
          <Reveal effect="fadeInUp" fraction={0.05}>
            <div className={`cell medium-8`}>
              <RichText
                render={doc.node.bottom_cta_heading}
                htmlSerializer={htmlSerializer}
              />

              <Button
                text={doc.node.bottom_cta_text}
                link={doc.node.bottom_cta_link}
                emoji={doc.node.bottom_cta_emoji}
                emojiLabel={doc.node.bottom_cta_emoji_label}
              />
            </div>
          </Reveal>
        </div>
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query WorkPageQuery {
    prismic {
      allCase_studys(sortBy: publish_date__order__DESC) {
        edges {
          node {
            ...caseStudies
          }
        }
      }
      allOur_works {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            page_heading
            banner_title
            secondary_title
            bottom_cta_heading
            bottom_cta_text
            bottom_cta_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            bottom_cta_emoji_label
            bottom_cta_emoji
          }
        }
      }
    }
  }
`

export default WorkPage
